import { debounce, throttle } from 'lodash';

class TopBannerOptions {
    bannerIsPresent: boolean = false;
}

export class SMTopAdBanner {
    options: TopBannerOptions = new TopBannerOptions();
    banner: any;
    floatNav: any;
    floatNavTrigger: any;
    header: HTMLElement;

    constructor(private optArg?: any) {
        this.setHeaderOffset = this.setHeaderOffset.bind(this);
        this.resetOffset = this.resetOffset.bind(this);
        this.setUtilityNavOffset = this.setUtilityNavOffset.bind(this);
        

        let bannerEl = document.querySelector('[data-sm-banner]'),
            headerEl = document.getElementById('js-header'),
            floatNav = document.getElementById('js-primary-nav'),
            floatMenuTrigger = document.getElementById('js-primary-nav__trigger');

        this.banner = bannerEl;
        this.header = headerEl;
        this.floatNav = floatNav;
        this.floatNavTrigger = floatMenuTrigger;
       

        window.addEventListener(
            'resize',
            debounce(() => {
                this.setHeaderOffset(this.banner, this.floatNav, this.floatNavTrigger, this.header);
                this.checkScrollPosition(window.pageYOffset);
            }, 50)
        );

        window.addEventListener(
            'scroll',
            throttle(() => {
                this.checkScrollPosition(window.pageYOffset);
         
            }, 50)
        );

        setTimeout(() => {
            this.checkScrollPosition(window.pageYOffset);
            this.addHeaderPadding();
        }, 500);

        if (this.optArg === undefined) this.optArg = new TopBannerOptions();
        else {
            this.options.bannerIsPresent =
                this.optArg.bannerIsPresent === undefined
                ? this.options.bannerIsPresent
                : this.optArg.bannerIsPresent;

            this.setHeaderOffset(this.banner, this.floatNav, this.floatNavTrigger, this.header);
        }

    }

    checkScrollPosition(scrollPosition) {
        let bannerHeight = this.banner.clientHeight,
            bannerScrollPos = this.banner.getBoundingClientRect();
        
        if (scrollPosition > 0 && scrollPosition < bannerHeight) {
            this.resetOffset(this.floatNav, this.floatNavTrigger, bannerScrollPos.top);
            this.header.classList.remove('header-fixed');
        } else if (scrollPosition > 0 && scrollPosition > bannerHeight) {
            this.resetOffset(this.floatNav, this.floatNavTrigger, 0);
            this.header.classList.add('header-fixed');
        } else {
            this.setHeaderOffset(this.banner, this.floatNav, this.floatNavTrigger, this.header);
            
        }
    }

    setHeaderOffset(banner, nav, navTrigger, header) {
        nav.style.top = banner.clientHeight + 'px';
        navTrigger.style.top = banner.clientHeight + 'px';
        this.setUtilityNavOffset(nav, banner.clientHeight);
    }

    resetOffset(nav, navTrigger, pos) {
        let curPos = this.banner.clientHeight,
            newPos = curPos + pos;

        if (pos !== 0) {
            nav.style.top = newPos + 'px';
            navTrigger.style.top = newPos + 'px';
        } else {
            nav.style.top = '0px';
            navTrigger.style.top = '0px';
        }
    }

    setUtilityNavOffset(mainNav, mainNavOffset) {
        let utilityNav = document.getElementById('js-utility-nav');

        let offset = mainNavOffset + mainNav.clientHeight;
        utilityNav.style.top = offset + 'px';
    }
    addHeaderPadding() {
        let bannerEl = document.querySelector('[data-sm-banner]');
        let header = document.querySelector('.header--mainHeader') as HTMLElement;
        let main = document.querySelector('main') as HTMLElement;
        if (bannerEl.clientHeight === 0) {
            main.style.paddingTop = '14rem';
            header.style.position = 'fixed';
        }
        return;
    }
}
