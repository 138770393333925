export class SMWindow {
    windowScript: any;
    constructor(private optArg?: any) {
        let windowScript = document.getElementById('smwindow').innerHTML;
        this.windowScript = windowScript;
        var left = (screen.width - 640) / 2;
        var top = (screen.height - 480) / 4;
        var params = "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=650, height=500, top=:" + top + ", left=" + left;
        //let newWin = open("", "_blank", params);
        //newWin.focus();
        //newWin.document.write('<title> Security Management </title>' + windowScript);
        //setTimeout(() => newWin, 1000);
        //newWin.onload = function () {
        //    newWin.document.write('<title> Security Management </title>' + windowScript);
        //};
    }
}
