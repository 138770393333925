import { SMTopAdBanner } from './modules/sm-ad-banner';
import { SMWindow } from './modules/smwindow';


// For SM Page Specific JS

(() => {
    // SM Page Top Ad Banner
    if (document.querySelector('[data-sm-banner]')) {
        let SMBanner = new SMTopAdBanner({
            bannerIsPresent: true
        });
    } 

    if (document.getElementById('smwindow')) {
        let smWindow = new SMWindow();
    }



})();

